<template>
  <div class="el_apply">
    <div class="lxwm-banner small">
      <div class="wrapper">
        <h2 class="cn">{{cn}}</h2>
        <h4 class="en">{{en}}</h4>
        <div class="line"></div>
      </div>
    </div>
    <div class="formpage-box ">
      <div class="wrapper formpage mform">
        <!-- <form> -->
        <ul>
          <li>
            <div class="minput-box">
              <!-- <el-input placeholder="姓名"
                        v-model="name">
                <template slot="prepend">
                  <div>
                    <img class="icon"
                         src="../../assets/images/icon53.png" />
                    Http://
                  </div>
                </template>
              </el-input> -->
              <div class="minput-l"><img class="icon"
                     src="../../assets/images/icon53.png" />姓名</div>
              <div class="minput-r">
                <input type="text"
                       class="text"
                       name=""
                       v-model="name"
                       placeholder="请输入您的姓名" />
              </div>
            </div>
          </li>
          <li>
            <div class="minput-box">
              <div class="minput-l"><img class="icon"
                     src="../../assets/images/icon54.png" />联系方式</div>
              <div class="minput-r">
                <input type="text"
                       class="text"
                       name=""
                       v-model="tel"
                       placeholder="请输入您的联系方式" />
              </div>
            </div>
          </li>
          <li>
            <div class="minput-box">
              <div class="minput-hd"><img class="icon"
                     src="../../assets/images/icon55.png" />详细描述</div>
              <div class="minput-bd">
                <textarea name=""
                          rows=""
                          cols=""
                          v-model="content"
                          placeholder="请详细描述您的问题"></textarea>
              </div>
            </div>
          </li>
        </ul>
        <div class="mform-btn">
          <button type="submit"
                  @click="toSubmit">提交</button>
        </div>
        <!-- </form> -->

      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'el_apply',
      name: '',
      tel: '',
      content: '',
      way: '',
      cn: '',
      en: '',
      id: '',
      typeArr: {
        1: '入驻申请',
        2: '入学培训',
        3: '在线咨询',
        4: '申请合作',
        5: '在线培训',
        6: '发布需求',
        7: '产业园咨询入驻',
        8: '人才城咨询入驻',
      },
      applyId: '', //工作站进入详情之后，点击的 业务咨询，过来的申请
      activeId: '',
    }
  },
  mounted () {


    this.way = this.$route.query.way || '';
    this.cn = this.$route.query.cn || '';
    this.en = this.$route.query.en || '';
    this.id = this.$route.query.id || '';
    this.type = this.$route.query.type || '';
    this.applyId = this.$route.query.applyId || '';
    this.activeId = this.$route.query.activeId || '';

    window._hmt.push(["_trackEvent", "page", "申请页面", this.cn]);

    console.log(this.cn, this.en, this.applyId, 999)

  },
  methods: {
    toSubmit () {
      let valit = this.warning();
      if (!valit) return;
      if (this.activeId) {
        this.addActivityJob();
      } else if (this.applyId) { // 工作站进入详情之后，点击的 业务咨询，过来的申请
        this.addWorkstation();
      } else if (this.way == 2) { // 园区服务-一站式大厅-企业服务-预约咨询
        this.getAddServiceOrder();
      } else { // 其他申请
        this.getAddWorkOrder();
      }
    },
    warning () {
      if (!this.name) {
        this.$message({ message: '请输入姓名', type: 'error' }); return false;
      } else if (!this.tel) {
        this.$message({ message: '请输入联系方式', type: 'error' }); return false;
      } else if (!/^1[3,4,5,7,8,9][0-9]{9}$/.test(this.tel)) {
        this.$message({ message: '请输入正确的联系方式', type: 'error' }); return false;
      } else if (!this.content) {
        this.$message({ message: '请输入详细描述', type: 'error' }); return false;
      }
      return true;
    },
    getAddServiceOrder () { // 园区服务-一站式大厅-企业服务-预约咨询
      let parames = {
        "contacts": this.name,
        "contactNumber": this.tel,
        "type": this.cn,
        "content": this.content,
        "parkId": this.id
      }
      this.api.getAddServiceOrder(parames).then(res => {
        console.log(res, 8888)
        if (res.data.code == 200) {
          if (document.body.offsetWidth > 1201) {
            this.$alert('您已提交' + this.cn + '相关咨询信息！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              callback: action => {
                window.close();
              }
            });
          } else {
            alert('您已提交' + this.cn + '相关咨询信息！');
            history.back()
          }
        }
      })
    },
    getAddWorkOrder () { // 其他表单
      let parames = {
        "contacts": this.name,
        "contactNumber": this.tel,
        "type": this.type,
        "content": this.content,
      }
      this.api.getAddWorkOrder(parames).then(res => {
        if (res.data.code == 200) {
          if (document.body.offsetWidth > 1201) {
            this.$alert('您已提交' + this.typeArr[this.type] + '相关信息！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              callback: action => {
                window.close();
              }
            });
          } else {
            alert('您已提交' + this.cn + '相关咨询信息！');
            history.back()
          }
        }
      })
    },
    addWorkstation () { // 业务咨询申请
      let parames = {
        "contacts": this.name,
        "contactNumber": this.tel,
        "gzzId": this.applyId,
        "content": this.content,
      }
      this.api.addWorkstation(parames).then(res => {
        if (res.data.code == 200) {
          if (document.body.offsetWidth > 1201) {
            this.$alert('您已提交' + this.cn + '相关信息！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              center: true,
              callback: action => {
                window.close();
              }
            });
          } else {
            alert('您已提交' + this.cn + '相关咨询信息！');
            history.back()
          }
        }
      })
    },
    addActivityJob () { // 园区服务-国际人才城-海创空间-活动-参加活动
      let parames = {
        "contacts": this.name,
        "contactNumber": this.tel,
        "activeId": this.activeId,
        "content": this.content,
      }
      this.api.getAddWorkOrder(parames).then(res => {
        if (res.data.code == 200) {

          if (document.body.offsetWidth > 1201) {
            this.$alert('您已提交' + this.cn + '相关信息！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              callback: action => {
                window.close();
              }
            });
          } else {
            alert('您已提交' + this.cn + '相关咨询信息！');
            history.back()
          }
        }
      })
    }

  }
}
</script>
<style scoped lang="scss">
</style>
